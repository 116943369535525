

.minimizable-web-chat {
    line-height: normal;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: $text-color;


    button:hover {
        cursor: pointer;
        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        transform: scale(1.1);
    }

    a {
        color: $link-color;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: $link-color-hover;
        }
    }

    button.maximize {
        background-color: $primary-color;
        border-radius: 50%;
        border: 2px solid $primary-color;
        bottom: 100px;
        box-shadow: 0 0 20px rgba(0, 0, 0, .2);
        color: white;
        font-size: 1.4em;
        outline: 0;
        padding: 16px;
        position: fixed;
        right: 20px;
        z-index: 10000;
        height: 60px;
        width: 60px;
        margin: 0;
        line-height: 1;

        &:active {
            background-color: white;
            color: $primary-color;
        }

        &:focus:not(:active),
        &:hover:not(:active) {
            background-color: $primary-color-hover;
            border-color: $primary-color-hover;
            color: $primary-color-text-hover;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            gap: 10px;

            .call-to-action {
                display: none;
                font-size: 0.9em;
                font-weight: 700;
                margin: auto;
            }

            .red-dot {
                color: #e85f5f !important;
                font-size: 14px !important;
                position: absolute !important;
                right: 5px !important;
                top: 12px !important;
            }
        }
    }

    .chat-box {
        background-color: rgba(223, 222, 222, 0.8);
        backdrop-filter: blur(10px);
        box-shadow: 0 0 20px rgba(0, 0, 0, .2);
        display: flex;
        flex-direction: column;
        position: fixed;
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        bottom: 0;
        left: 0;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        header {
            background-color: $primary-color;
            height: 40px;
            display: flex;
            flex-flow: row nowrap;
            padding-top: 5px;
            color: $primary-color-text;

            button {
                width: 40px;
                height: 40px;
                background-color: Transparent;
                border: 0;
                outline: 0;
                color: $primary-color-text;

                &:hover:not(:active) {
                    color: $primary-color-text-hover;
                }

                &:focus:active {
                    color: #FFFF;
                }
            }

            .switch {
                display: none;
            }

            .branding {
                padding: 5px;
                font-size: 1.1em;
                font-weight: bolder;
                margin-left: 5px;
                white-space: nowrap; /*This is important because <span> normally wraps when it encounters whitespace*/
            }
        }

        .disclaimer-container {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-start;
            justify-content: space-around;
            padding: 5px 10px;
            font-size: 0.8em;
            gap: 10px;
            box-shadow: 0 5px 4px rgba(40, 48, 66, .08);
            border-radius: 0 0 5px 5px;
            z-index: 100001;
            animation: slideDown .3s ease-out;
            background-color: $notification-color;

            .disclaimer-text {
                margin: 5px 15px;
                color: black;
                text-align: left;
            }

            button {
                width: 40px;
                height: 40px;
                background-color: Transparent;
                border: 0;
                outline: 0;
                font-size: 17px;

                &:hover:not(:active) {
                    color: $primary-color;
                }

                &:focus:active {
                    color: $primary-color-text-hover;
                }
            }
        }

        .chatbot-feedback {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-evenly;
            background-color: $bg-color;
            color: black;
            padding: 2px;
            //margin: 1px 1px 0px 50px;
            font-size: 15px;
            animation: slideRight .2s ease-out;

            .feedback-filler {
                flex: 0 100000 100%;
            }

            .feedback-container {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-evenly;
                gap: 5px;

                .thumbs_up_down_container {
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    justify-content: space-evenly;
                    gap: 2px;



                    .thumbs_up_down {
                        border: none;
                        text-decoration: none;
                        background-color: $bg-color;
                        font-size: 16px;
                        color: black;
                        border-radius: 40%;
                        padding: 0.3em;
                    }

                    .thumbs_up_down:hover {
                        background-color: $primary-color-hover;
                        color: $primary-color-text-hover;
                        transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                        transform: scale(1.1);
                    }
                    //.thumb_down {
                    //transform: rotateY(180deg);
                    //}
                }
            }

            .small-filler {
                width: 10px;
            }
        }

        .user-feedback {
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            background-color: $primary-color;
            gap: 10px;
            z-index: 10001;
            height: 150px;
            width: calc(100% - 20px);
            padding: 10px;
            color: $primary-color-text;
            animation: slideUp .2s ease-out;


            .feedback-header {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                width: 100%;

                .feedback-header-content {
                    display: flex;
                    align-items: center;
                    gap: 5px;

                    .separator {
                        flex: 0 100000 100%;
                    }
                }

                .send-comment {
                    border: none;
                    background-color: inherit;
                    font-size: 15px;

                    div {
                        display: flex;
                        gap: 5px;
                        padding: 5px;
                        border-radius: 30%;
                        color: $primary-color-text;
                        font-weight: bold;

                        .skip-btn {
                            font-size: 18px;
                        }
                    }

                    div:hover {
                        background-color: $primary-color-hover;
                        color: $primary-color-text-hover;
                    }
                }
            }

            .user-comment {
                font-family: inherit;
                width: calc(100%);
                height: 120px;
                padding: 10px;
                font-size: 16px;
                background-color: #f9f9f9;
                border: 1px solid #ddd;
                border-radius: 5px;
                box-sizing: border-box;
                resize: none;
            }
        }

        .confirmation-feedback {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            background-color: $notification-color;
            border-radius: 10px;
            padding: 5px 10px;
            margin: 5px;
            font-size: 12px;
            gap: 5px;
            width: auto;
            box-shadow: 0 0 15px rgba(0, 0, 0, .2);
            color: black;

            .separator {
                width: 5px;
            }

            .separator-dynamic {
                flex: 0 100000 100%;
                max-width: 20px;
            }

            .confirmation-text {
                font-weight: bold;
            }
        }

        .tools-container {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            padding: 2px 0 5px 5px;
            background-color: white;

            button {
                background-color: Transparent;
                border: 0;
                outline: 0;
                color: $tools-color;

                &:hover:not(:active) {
                    color: $tools-color-hover;
                    cursor: pointer;
                }

                &:focus:active {
                    color: $tools-color-active;
                }
            }

            .dark-bg {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.4);
                z-index: 10001;
            }

            .lang-icon {
                display: flex;
                gap: 5px;
                background-color: Transparent;
                border: 0;
                outline: 0;
                color: $tools-color;
                align-items: center;

                span {
                    font-weight: bold;
                }
            }

            .lang-selector {
                @include lang-popup-styles;
                max-height: 300px;
                width: 140px;
                animation: slideUp .2s ease-out;

                .lang-list {
                    list-style: none;
                    padding-left: 20px;
                    margin: 1em 0;


                    li {
                        color: $tools-color;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        &:hover:not(:active) {
                            color: black;
                            background-color: $bg-color;
                            padding: 5px 5px 5px 10px;
                            margin-right: 5px;
                            border-radius: 10px;
                            border: 1px solid #ccc;
                        }

                        &:focus:active {
                            color: $primary-color-text-hover;
                        }

                        .flag-icon {
                            @include flag-icon-styles;
                        }
                    }
                }

                .all-lang-link {
                    font-size: 0.7em;
                    padding: 0 10px;
                    color: $link-color;
                    display: flex;
                    gap: 7px;
                    align-items: center;

                    &:hover {
                        color: $link-color-hover;
                        cursor: pointer;
                    }
                }
            }

            .all-lang-container {
                @include lang-popup-styles;
                left: 50%;
                transform: translateX(-50%);
                animation: slideRight .2s ease-out;
                display: flex;
                flex-flow: column wrap;
                align-items: center;
                justify-content: center;
                min-width: 280px;


                .all-lang-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    padding: 10px 10px 0 10px;

                    .all-lang-button:hover {
                        color: $link-color-hover;
                        cursor: pointer;
                    }
                }

                .all-lang-label-container {
                    position: absolute;
                    top: 40px;
                    z-index: 10002;
                    background-color: $notification-color;
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 0px 15px;
                    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


                    .all-lang-label-text {
                        font-size: 0.7em;
                        max-width: 250px;
                        font-weight: bold;
                        margin: 5px 15px;
                    }
                }

                .all-lang-list {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 10px;
                    align-items: center;
                    padding: 0 20px;
                    margin: 1em 0;
                }

                .all-lang-list li {
                    list-style-type: none;
                    display: flex;
                    align-items: center;
                }

                .all-lang-list .flag-icon {
                    @include flag-icon-styles;
                }
            }
        }

        footer {
            height: auto;
            display: flex;
            flex-flow: column wrap;
            align-items: center;
            justify-content: center;
            background-color: $primary-color;
            color: $primary-color-text;
            font-size: 0.7em;
            text-align: center;
            min-height: 30px;

            a {
                color: $primary-color-text;
                text-decoration: underline;

                &:hover,
                &:focus {
                    color: $primary-color-text-hover;
                }
            }

            .policies {
                font-weight: 700;
                padding: 3px 0 5px 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 5px;
            }
        }

        .react-web-chat {
            flex: 1;
        }

        &.hide {
            display: none;
        }
    }

    .filler {
        flex: 0 10000 100%;
    }
}

/*When viewport is bigger than 600px*/
@media (min-width: 600px) {

    .minimizable-web-chat {
        button.maximize {
            border-radius: 60px;
            width: auto;

            div {
                .call-to-action {
                    display: inline-block;
                }

                .red-dot {
                    right: 110px;
                }
            }
        }

        .chat-box {
            bottom: 20px;
            max-width: 400px;
            min-width: 330px;
            max-height: 750px;
            min-height: 300px;
            height: calc(100% - 80px);
            width: 30%;
            overflow: hidden;
            perspective: 1px;
            border-radius: .5rem;

            header {
                .switch {
                   display: block;
                }
            }

            &.left {
                left: 20px;
                right: auto;
            }

            &.right {
                right: 20px;
                left: auto;
            }
        }
    }
}



