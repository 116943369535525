@import url('https://fonts.googleapis.com/css?family=Poppins');

$primary-color: #D71750;
//$primary-color: #FF8204;
$primary-color-hover: darken($primary-color, 10%);
$primary-color-text: #FFFFFF;
$primary-color-text-hover: darken($primary-color-text, 20%);

$link-color: #383937;
$link-color-hover: #808285;

$text-color: #383937;

$bg-color: #EFEFEF;

//$notification-color: lighten($primary-color, 30%);
$notification-color: #E686A2;

$chat-bubble-color: lighten(#FF8204, 50%);
$tools-color: #D71750;
$tools-color-hover: darken(#D71750, 10%);
$tools-color-active: lighten(#D71750, 20%);

@keyframes slideUp {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}
@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}
@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0); 
    }
}
@keyframes slideLeft {
    0% {
        transform: translateX(100%); 
    }

    100% {
        transform: translateX(0); 
    }
}

@mixin flag-icon-styles {
    width: 20px;
    height: 15px;
    margin-right: 5px;
    //border: 1px solid $bg-color;
    box-shadow: 0 5px 4px rgba(40, 48, 66, .1);
}

@mixin lang-popup-styles {
    position: absolute;
    bottom: 100px;
    z-index: 100002;
    margin-bottom: 5px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
}
